.sectionUl {
    list-style: none;
    margin: 1.8rem auto;
    display: flex;
    flex-direction: row;
    width: 40%;
    justify-content: space-between;
    font-size: 1.5rem;
    height: 2.9rem;
    align-items: flex-end;
}

.dotDiv {
    display: flex;
    align-items: center;
    width: 70%;
    justify-content: center;
    margin: -9rem auto 0;
}

.sectionLi {
    color: white;
    z-index: 1;
}

.sectionLi:hover {
    color: #F94C66;
}

.section1 {
    text-align: center;
}

.floater {
    justify-self: flex-start;
    align-self: flex-end;
    width: 0;
    z-index: 1;
}

.words {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 90%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.word{
    width: 100%;
    height: 47rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url('../assets/morris.PNG'), rgba(27, 26, 26, 0.721);
    background-blend-mode: darken;
    background-position: center;
    background-size: cover;
}

.box {
    height: 100%;
    margin: 2rem auto;
    display: flex;
    align-items: center;
    width: 70%;
    color: white;
}

.h1 {
    font-size: 10rem;
    text-decoration: underline;
    margin: 0;
    text-align: left;
}

.p {
    font-size: 2rem;
    text-align: center;
    margin: 0;
}

.p1 {
    font-size: 5rem;
}

.icon {
    margin: 0 1rem;
    font-size: 38px;
    color: #F94C66;
}

.logo {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image {
    height: 90%;
}

.h4 {
    font-size: 2rem;
    position: relative;
    animation: right;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    margin: 0 auto;
    width: 100%;
}

.anime {
    margin: 0;
    overflow: hidden;
    width: 100%;
    text-align: right;
    border: solid #F94C66;
    border-width: 0 1rem 0 0;
}

@keyframes right {
    0% {
        right: -40rem;
    }

    50% {
        right: 3rem;
    }

    100% {
        right: -40rem;
    }
}

.words{
    overflow: hidden;
}

.iconDiv {
    text-align: left;
}

.icons {
    margin: 0 1rem 0 0;
    color: #F94C66;
}

.last {
    color: white;
    width: 100%;
    margin: 1rem 1rem 0;
    width: fit-content;
}

@media screen and (max-width : 800px) {
    .sectionUl{
        display: none;
    }

    .dotDiv{
        display: none;
    }

    .box{
        width: 100%;
    }

    .h1{
        font-size: 4rem;
    }

    .h4{
        font-size: 1.4rem;
        margin: 0;
    }

    .word{
        width: 100%;
        margin: 0 auto;
    }

    .words{
        width: 90%;
        margin: 0 auto;
    }

    .floater{
        width: 0;
        z-index: 1;
    }

.p{
    font-size: 1.4rem;
    text-align: center;
    width: 100%;
}
}

@media screen and (min-width :800px) and (max-width : 1200px) {
    .sectionUl {
        display: none;
    }

    .dotDiv {
        justify-content: space-around;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .box {
        width: 100%;
    }

    .h1 {
        font-size: 7rem;
    }

    .h4 {
        font-size: 1.9rem;
        margin: 0;
    }

    .anime{
        width: 95%;
    }

    .word {
        width: 100%;
        margin: 0 auto;
    }

    .floater {
        width: 0;
        z-index: 1;
    }

    .p {
        font-size: 1.4rem;
        text-align: center;
        width: 70%;
    }
}