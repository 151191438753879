.app {
  margin: 0;
  padding: 0 1rem;
}

.video{
  width: 100%;
}

.animator {
  position: relative;
  animation: moving;
  animation-duration: 1s;
  -webkit-animation: moving;
  -webkit-animation-duration: 1s;
}

@keyframes moving {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes moving {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@media screen and (max-width : 800px) {
  .app {
    padding: 0 1rem;
  }

  .video{
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    margin-top: -2rem;
  }
}