.nav {
    margin: 0;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0;
    color: white;
z-index: 10;
}

.float {
    float: right;
}

.navDiv {
    background-color: #F94C66;
    width: 28rem;
    height: 37rem;
    clip-path: polygon(27% 0, 100% 0, 100% 100%, 34% 100%, 0 50%);
    position: relative;
    animation: faded;
    animation-duration: 1s;
    margin: 0;
    float: right;
}

@keyframes faded {
    from {
        opacity: 0
    }

    ;

    to {
        opacity: 1
    }
}

.morris {
    margin: 20px 1rem;
    float: left;
}

.dj {
    color: #F94C66;
}

.btn {
    width: 9rem;
    height: 6rem;
    background-color: #F94C66;
    color: white;
    border: none;
    outline: none;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
}

.material-symbols-outlined {
    font-size: 3rem;
    margin-left: 15px;
}

.ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    font-size: 2.3rem;
    margin: 0 1rem;
    padding: 0 1rem;
    text-transform: uppercase;
}

.li {
    margin: .2rem;
    text-decoration: none;
    color: white;
}


.li1 {
    margin: .2rem;
    text-decoration: none;
    color: white;
}

.li1:hover {
    width: 100%;
    text-align: center;
    transition: all .5s;
    background-color: white;
    color: red;
}

.li:hover {
    width: 100%;
    text-align: center;
    transition: all .5s;
    background-color: white;
    color: red;
}

@media screen and (max-width : 800px) {
    .btn {
        width: 6rem;
        height: 4rem;
    }

    .navDiv {
        float: none;
        display: block;
        width: 100%;
        clip-path: none;
        position: absolute;
        left: -1rem;
        height: fit-content;
        background-color: rgb(0, 0, 0);
    }

    .morris {
        margin: 1rem;
        font-size: 1.4rem;
    }

    .nav {
        position: fixed;
        width: 100%;
        display: block;
        background-color: rgb(0, 0, 0);
    }

    .material-symbols-outlined {
        font-size: 2.5rem;
    }

    .ul {
        font-size: 1.2rem;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        background-color: black;
    }

    .li1{
        display: none;
    }

    .li {
        display: block;
        width: 100%;
        margin: .2rem 0;
        padding: 5px 1rem;
        background-color: #F94C66;
    }
    .li:hover{
        background-color: #F94C66;
        color: white;
        text-align: left;
        transition: none;
    }
}