.round {
    background-color: #F94C66;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: block;
    margin: 1.5rem;
}

.arrow{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10rem;
    margin: 0;
    padding: 0;
}

.triangle {
    margin: 1rem 1.5rem -2.3rem;
    color: white;
    font-size: 14rem;
    display: block;
}

@media screen and (max-width : 600px) {
    .triangle{
        font-size: 7rem;
        margin: 1rem 0 -1rem;
    }

    .round{
        width: 2rem;
        height: 2rem;
        margin: 1rem;
    }

    .arrow{
        width: 7rem;
    }

    .navDiv{
        display: block;
    }
}

@media screen and (min-width :600px) and (max-width : 1200px) {

    .triangle{
        font-size: 10rem;
        margin: 1rem 0 -1rem;
    }

    .round{
        width: 2.5rem;
        height: 2.5rem;
        margin: 1rem;
    }

    .arrow{
        width: 7rem;
    }

    .navDiv{
        display: block;
    }
}