.section4{
    color: white;
    width: 70%;
    margin: 5rem auto;
}

.img{
    width: 22rem;
    margin: 1rem 0;
    transition: all ease .3s;
}

.photos{
    font-size: 3.5rem;
}

.img1{
    width: 22rem;
    height: 22rem;
    margin: 1rem 0;
    transition: all ease .3s;
}

.section4img{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.section4btn{
    display: block;
    width: 10rem;
    height: 3rem;
    background-color: #F94C66;
    color: white;
    font-size: 1.3rem;
    border: none;
}

@media screen and (max-width : 800px) {
    .section4{
        width: 100%;
    }

    .section4img{
        justify-content: space-evenly;
    }

    .photos{
        font-size: 2.5rem;
    }
}

@media screen  and (max-width :1200px){
    .section4{
        width: 100%;
    }

    .section4img{
        justify-content: space-evenly;
    }
}