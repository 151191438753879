.section7 {
    color: white;
    width: 70%;
    margin: 4rem auto;
}

.section7img {
    width: 60%;
}

.section7h1 {
    font-size: 3.5rem;
    margin: 0 0 -3rem;
}

.section7div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input {
    display: block;
    width: 100%;
    height: 4rem;
    margin: 0 0 1.5rem;
    padding-left: 1rem;
    font-size: 1.3rem;
    border: none;
}

.input:focus {
    outline-color: #F94C66;
    border-color:  #F94C66 ;
}

.text {
    font-size: 1.3rem;
    width: 100%;
    height: 16rem;
    margin: 0 0 1rem;
    padding: 1rem;
}

.text:focus{
    outline-color: #F94C66;
    border-color:  #F94C66 ;
}

.section7form {
    width: 100%;
}

@media screen and (max-width: 800px) {
    .section7img{
        display: none;
    }

    .section7{
        width: 100%;
    }

    .section7div{
        display: block;
    }

    .input{
        width: 80%;
        height: 3rem;
    }

    .text{
        width: 80%;
    }

    .section7h1{
        margin: 2rem 0;
    }

    .section7h1{
        font-size: 2.5rem;
    }
}

@media screen and (max-width : 1200px) {
    .section7{
        width: 100%;
    }

    .section7h1{
        margin: 2rem 0;
    }
    
}