.btn3 {
    width: 15rem;
    height: 4rem;
    font-size: 1.2rem;
    background-color: #F94C66;
    color: white;
    display: block;
    border: none;
    outline: none;
}

.btn3:hover {
    background-color: antiquewhite;
    color: red;
}

@media screen and (max-width : 800px) {
    .btn3 {
        width: fit-content;
        padding: 0 1rem;
        height: 3rem;
        font-size: 1rem;
        margin: 0;
    }
}